define("discourse/plugins/discourse-calendar/discourse/connectors/discovery-list-container-top/category-events-calendar", ["exports", "@glimmer/component", "@ember/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryEventsCalendar extends _component.default {
    static shouldRender(_1, ctx1) {
      return ctx1.siteSettings.calendar_categories_outlet === "discovery-list-container-top";
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div id="category-events-calendar"></div>
      
    */
    {
      "id": "sjNSeZY9",
      "block": "[[[1,\"\\n    \"],[10,0],[14,1,\"category-events-calendar\"],[12],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/connectors/discovery-list-container-top/category-events-calendar.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryEventsCalendar;
});